import styled from 'styled-components';

export const Nav = styled.nav`
    background-color: ${ props => props.theme.darkColor };
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 15;
    padding: 1rem;

    .logo {
        font-family: ${ props => props.theme.secondaryFont };
        font-size: 2rem;
        
        a {
            color: ${ props => props.theme.lightColor };
        }
    }

    .nav-toggle {
        display: none;
        color: ${ props => props.theme.lightColor };

        i {
            font-size: 1.5rem;
        }
    }

    .desktop-navigation, .mobile-navigation {

        ul {
            display: flex;
            align-items: center;

            li {

                margin-left: 1.5rem;

                a{
                    color: ${ props => props.theme.lightColor };
                    font-weight: 500;
        
                    &:hover {
                        color: ${ props => props.theme.primaryColor };
                        transition: all 0.5s ease-in-out;
                    }
                }

            }

        }

    }

    .mobile-navigation {
        display: none;
    }

    .social-icons{
        display: flex;
        align-items: center;
    }

    @media (max-width: 768px){

        justify-content: space-between;
        padding-left: 2rem;
        padding-right: 2rem;

        .nav-toggle {
            display: block;
            position: relative;
            z-index: 11;
        }

        .mobile-navigation {

            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background-color: ${props => props.theme.darkColor };
            opacity: 0.9;
            z-index: 10;

            ul {
                flex-direction: column;
                justify-content: center;

                li {
                    margin-left: 0;
                    margin-bottom: 1.5rem;
                }
            }
        }

        .desktop-navigation {
            display: none;
        }

        .social-icons {
            display: none;
        }

    }  
`