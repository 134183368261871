import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { GlobalStyle, theme } from './../../elements/SharedElements';
import Navigation from './Navigation';
import Footer from './Footer';
import Covid from './Covid';

config.autoAddCss = false;

const Layout = (props) => {

    const title = props.title || "Web Developer | UX/UI";

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Helmet>
                <title>Prince Rathupa | { title }</title>
                <meta name="description" content="Prince Rathupa is a professional Full Stack Web Developer with 3+ years’ experience in building web applications for one of the big media companies in South Africa." />
                <meta name="keywords" content="Prince, Rathupa, Web Development, South Africa, Johannesburg, Web design, Front end, Full Stack, Gauteng, Pretoria, React, Wepback, Firebase" />
            </Helmet>
            <Covid />
            <Navigation />
            {props.children}
            <Footer />
        </ThemeProvider>
    )

}

export default Layout;