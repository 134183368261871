import styled, { createGlobalStyle } from 'styled-components';

export const theme = {
    primaryColor: "#0CA7F5",
    secondaryColor: "#5E16E3",
    darkColor: "#000",
    lightColor: "#fff",
    greyColor: "#f7f7f7",
    darkGreyColor: "#ababab",
    primaryFont: "Roboto",
    secondaryFont: "Chivo"
}

export const Covid19 = styled.header`

    padding: 1rem;

    .container {

        p {
            text-align: center;
            color: #009144;

            a {
                color: #009144;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

    }

    @media (max-width: 500px){

        .container {
    
            p {
                font-size: 0.6rem;
            }

        }

    }

`

export const Stack = styled.section`

    background: ${ props => props.theme.lightColor };;
    padding: 1rem 0;

    @media (max-width: 1024px){

        padding-left: 2rem;
        padding-right: 2rem;

    }

    .container {

        .tech-stack {

            h2{

                font-size: 2rem;
                margin-bottom: 1.5rem;

                svg {
                    color: ${ props => props.theme.darkGreyColor };
                }

            }

            .tech-stack-container {

                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .tech-item {

                    width: 14%;
                    margin-bottom: 2rem;

                    img{

                        width: 50%;
                        display: block;

                    }

                }
            }
        }

    }

    @media (max-width: 768px){

        .container {
    
            .tech-stack {


                .tech-stack-container {

                    .tech-item {
                        width: 30%; 
                        height: 5rem;
                    }

                }

            }

        }

    }

    @media (max-width: 768px){

        padding-left: 1rem;
        padding-right: 1rem;

        .container {

            .tech-stack {

                .tech-stack-container {

                    .tech-item {
                        display: flex;
                        justify-content: center;
                    }

                }

            }

        }

    }

`
export const HeaderBlock = styled.header`

    position: relative;
    background: no-repeat center center /cover;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${ props => props.theme.darkColor };
        opacity: 0.7;
        z-index: 1;
        overflow: hidden;
    }

    .container{
        display: flex;
        height: 35rem;
        justify-content: center;
        align-items: center;
        color: ${ props => props.theme.lightColor };
        position: relative;
        top: 0;
        left: 0;
        z-index: 2;

        h1{
            font-size: 2.2rem;
        }

        .about-intro{

            p {
                padding: 1rem 1.5rem;
                font-size: 1.3rem;
            }

        }

    }

    @media (max-width: 500px){

        .container {

            height: 25rem;

            h1 {

                font-size: 1.8rem;

            }

        }

    }

`

export const GlobalStyle = createGlobalStyle`
    
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    body {
        font-family: ${ props => props.theme.primaryFont };
        line-height: 1.2;
        background-color: ${ props => props.theme.lightColor };
    }

    ul {
        list-style-type: none;
    }

    a {
        text-decoration: none;
        color: ${ props => props.theme.primaryColor };
        font-weight: 500;
    }

    .container {
        max-width: 1100px;
        margin: auto;
    }

    .active {
        color: ${ props => props.theme.primaryColor } !important;
    }
    
    .social-icons{
        
        a{
            color: ${ props => props.theme.lightColor };
            margin-left: 0.8rem;
        }
    
    }

    .effect {
        color: ${props => props.theme.primaryColor };
    }

    .btn {
        cursor: pointer;
        color: ${ props => props.theme.lightColor };
        font-size: 1.1rem;
        padding: 0.8rem 1.5rem;
        display: inline-block;
        border-radius: 0.5rem;
        border: none;
        background-color: ${ props => props.theme.primaryColor};
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: ${props => props.theme.secondaryFont}
    }
`

