import React, { useState } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faGithub, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
import { Nav } from './../../elements/NavigationElements';

const Navigation = () => {

    const [state, setState ] = useState(false);

    const toggleMenu = () => {
        setState(!state);
    }

    return (
        <Nav>
            <h1 className="logo"><Link to="/">Prince<span className="effect"> Rathupa;</span></Link></h1>
            <div className="desktop-navigation">
                <ul>
                    <li><Link to="/" activeClassName="active">Home</Link></li>
                    <li><Link to="/about" activeClassName="active">About</Link></li>
                    <li><Link to="/services" activeClassName="active">Services</Link></li>
                    <li><Link to="/resume" activeClassName="active">Resume</Link></li>
                    <li><Link to="/contact" activeClassName="active">Contact</Link></li>
                </ul>
            </div>
            <div className="nav-toggle">
                { state ? <FontAwesomeIcon onClick={toggleMenu} icon={faTimes} /> : <FontAwesomeIcon onClick={toggleMenu} icon={faBars} /> }
            </div>
            { state && <div className="mobile-navigation">
                <ul>
                    <li><Link to="/" activeClassName="active">Home</Link></li>
                    <li><Link to="/about" activeClassName="active">About</Link></li>
                    <li><Link to="/services" activeClassName="active">Services</Link></li>
                    <li><Link to="/resume" activeClassName="active">Resume</Link></li>
                    <li><Link to="/contact" activeClassName="active">Contact</Link></li>
                </ul>
            </div> }
            <div className="social-icons">
                <a href="https://twitter.com/ke_princie" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
                <a href="https://www.instagram.com/princie.js/" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
                <a href="https://linkedin.com/in/prince-rathupa-785725143/" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a>
                <a href="https://github.com/rathupap/" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faGithub} /></a>
            </div>
        </Nav>
    )
    

}
export default Navigation;