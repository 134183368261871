import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faGithub, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { Foot } from './../../elements/FooterElements';

const Footer = () => (
    <Foot>
        <div className="container">
            <div className="social-icons">
                <a href="https://twitter.com/ke_princie" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
                <a href="https://www.instagram.com/princie.js/" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
                <a href="https://linkedin.com/in/prince-rathupa-785725143/" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a>
                <a href="https://github.com/rathupap/" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faGithub} /></a>
            </div>
            <p>Copyright &copy; 2020 - All Rights Reserved</p>
            <p>Designed and Developed With <FontAwesomeIcon icon={faHeart} /> By Prince <span className="effect">Rathupa</span></p>
        </div>
    </Foot>
)

export default Footer;