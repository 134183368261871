import styled from 'styled-components';

export const Foot = styled.footer`
    background-color: ${ props => props.theme.darkColor };
    padding: 2rem;

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        color: ${ props => props.theme.lightColor };
        height: 5rem;
    }

    p {
        svg {
            color: ${ props => props.theme.secondaryColor };
        }
    }

    @media (max-width: 500px){

        padding: 2rem 0.5rem;

        .container {

            p {
                font-size: 0.8rem;
            }

        }

    }
`