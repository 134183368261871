import React from 'react';
import { Covid19 } from './../../elements/SharedElements';

const Covid = () => (
    <Covid19>
        <div className="container">
            <p>Stay home, stay safe. For all official information and updates regarding Covid-19 
            please visit: <a href="http://www.sacoronavirus.co.za" rel="noreferrer" target="_blank">www.sacoronavirus.co.za</a>
            </p>
        </div>
    </Covid19>
)

export default Covid;